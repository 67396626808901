@media screen and (min-width: 500px) {
    html {
        font-size: 105%;
    }
    /* HEADER */
    .header .branding img {
        /*width: 9.375em;
        height: 2.25em;*/
    }
    /* LEGIBLE */
    .legible {
        max-width: 40em;
        margin-left: 1.75em;
        margin-right: 1.75em;
    }
    .legible p a,
    .legible li a {
    background-position: 0 1.25em;
}
    /* GRID TYPE 2 */
    .grid-container-type-2 {
      
    }
    
    /* TABLE */
    table {
        margin: 2.8em 0;
        width: 100%;
    }
    /* PRICING TABLE */
    .time-table,
    .pricing-table {
        padding-left: 2em;
        padding-right: 2em;
    }
    .time-table h3,
    .pricing-table h3{
        text-align: left;
        margin-left: 0;
    }
    .time-table p,
    .pricing-table p {
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        line-height: 1.3em;
    }
}
